import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


export const BottomSheet = ({ isOpen, onClose, type, message }) => {
    
    const getIcon = () => {
        switch (type) {
            case 'error':
                return <FontAwesomeIcon icon={faTimesCircle} className="text-red-500 p-4" size='6x'/>;
            case 'success':
                return <FontAwesomeIcon icon={faCheckCircle} className="text-lime-400 p-4" size='6x'/>;
            default:
                return null;
        }
    };

    return (
        <div
            className={`fixed inset-0 z-50 transition-opacity duration-300 ${
                isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
            }`}>

            <div
                className="absolute inset-0 bg-black opacity-50"
                onClick={onClose}
            ></div>
            <div
                className={`absolute inset-x-0 bottom-0 bg-white rounded-t-lg shadow-lg transition-transform duration-300 ${
                    isOpen ? 'translate-y-0' : 'translate-y-full'
                }`}
                style={{ maxHeight: '90vh' }}
            >
                <div className="relative">
                    <div className="absolute inset-x-0 top-0 flex justify-center">
                        <div className="w-24 h-1 bg-gray-300 my-2"></div>
                    </div>
                    <div className={`p-4 overflow-y-auto`}>
                        <div className="flex justify-center items-center mb-4">
                            {getIcon()}
                        </div>
                        <p className="text-lg  text-center">{message}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const BlankBottomSheet = ({isOpen,onClose,children}) => {
    return (
        <div
        className={`fixed inset-0 z-50 transition-opacity duration-300 ${
            isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}>

        <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={onClose}
        ></div>
        <div
            className={`absolute inset-x-0 bottom-0 bg-white rounded-t-lg shadow-lg transition-transform duration-300 ${
                isOpen ? 'translate-y-0' : 'translate-y-full'
            }`}
            style={{ maxHeight: '90vh' }}>
            <div className="relative">
                {children}
            </div>
        </div>
    </div>
    );
}


