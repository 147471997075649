import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const ProductWidget = ({ products, type }) => {
    const navigate = useNavigate();
    const handleProductClick = (productId) => {
        navigate(`product/${productId}`);
    };

    const renderProductGrid = () => (
        <div className="product-list overflow-hidden">
            {products.map((product) => (
                <div
                    key={product.id}
                    className="product-card mb-4"
                    data-product-id={product.id}
                    onClick={() => handleProductClick(product.id)}>
                    <span type="hidden" product-id={product.id}></span>
                    <div className="product-image">
                        <img
                            src={`https://pos.torufarm.com/storage/app/public/product/${product.image[0]}`}
                            alt={product.name}
                            width="110"
                        />
                    </div>
                    <div className="product-info">
                        <h5>{product.name}</h5>
                        <small>Rp {product.price}</small>
                        <p className="variant">{product.variant || ''}</p>
                        <button className="add-btn bg-green-700">+</button>
                    </div>
                </div>
            ))}
        </div>
    );

    const renderProductLine = () => (
        <div className="product-list">
            {products.map((product) => (
                <div
                    key={product.id}
                    className="product-card"
                    data-product-id={product.id}
                    onClick={() => handleProductClick(product.id)}
                >
                    <div className="product-image">
                        <img
                            src={`https://pos.torufarm.com/storage/app/public/product/${product.image[0]}`}
                            alt={product.name}
                            width="110"
                        />
                    </div>
                </div>
            ))}
        </div>
    );

    return type === 'grid' ? renderProductGrid() : renderProductLine();
};

export default ProductWidget;