const AppBar = ({title}) => {
    return(
        <div className="flex items-center mb-1 max-w-lg mx-auto p-4 bg-white">
            <button className="text-xl text-white-500 mr-3" onClick={() => window.history.back()}>
                <i className="fas fa-arrow-left text-lime-900"></i>
            </button>
            <h1 className="text-2xl text-lime-900 poppins-light">{title}</h1>
        </div>
    );
}

export default AppBar;