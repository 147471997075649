
import { Link } from 'react-router-dom';
import AppBar from '../../../component/app_bar';


const Cart = () => {
    return (
        <div>
    <div className="max-w-lg mx-auto bg-gray-50">
        
        <AppBar title={'Keranjang'}/>

        <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 250px)' }}>
            <div className="cart-items mb-0">
                <div className="cart-item bg-white p-4 mb-0 flex items-center">
                    <input type="checkbox" className="mr-3" />
                    <div className="w-16 h-16 bg-gray-400 mr-3 rounded"></div>
                    <div className="item-details flex-1">
                        <p className="font-bold">Ikan Katombo</p>
                        <p className="text-sm text-gray-600">Rp 23.000 &times; 2</p>
                        <span className="text-xs bg-orange-500 text-white py-1 px-2 rounded">Big Sale</span>
                    </div>
                    <div className="item-actions flex items-center ml-auto bg-gray-50 rounded border-1 border-gray-300 overflow-hidden w-20 h-8 relative">
                        <button className="text-red-500 w-10 h-full font-bold text-lg hover:bg-red-50">-</button>
                        <span className="flex-grow text-center text-lg font-semibold">2</span>
                        <button className="text-green-500 w-10 h-full font-bold text-lg hover:bg-green-50">+</button>
                    </div>
                </div>
                <div className="cart-item bg-white p-4 mb-0 flex items-center">
                    <input type="checkbox" className="mr-3" />
                    <div className="w-16 h-16 bg-gray-400 mr-3 rounded"></div>
                    <div className="item-details flex-1">
                        <p className="font-bold">Ikan Katombo</p>
                        <p className="text-sm text-gray-600">Rp 23.000 &times; 2</p>
                        <span className="text-xs bg-orange-500 text-white py-1 px-2 rounded">Big Sale</span>
                    </div>
                    <div className="item-actions flex items-center ml-auto bg-gray-50 rounded border-1 border-gray-300 overflow-hidden w-20 h-8 relative">
                        <button className="text-red-500 w-10 h-full font-bold text-lg hover:bg-red-50">-</button>
                        <span className="flex-grow text-center text-lg font-semibold">2</span>
                        <button className="text-green-500 w-10 h-full font-bold text-lg hover:bg-green-50">+</button>
                    </div>
                </div>
                <div className="cart-item bg-white p-4 mb-0 flex items-center">
                    <input type="checkbox" className="mr-3" />
                    <div className="w-16 h-16 bg-gray-400 mr-3 rounded"></div>
                    <div className="item-details flex-1">
                        <p className="font-bold">Ikan Katombo</p>
                        <p className="text-sm text-gray-600">Rp 23.000 &times; 2</p>
                        <span className="text-xs bg-orange-500 text-white py-1 px-2 rounded">Big Sale</span>
                    </div>
                    <div className="item-actions flex items-center ml-auto bg-gray-50 rounded border-1 border-gray-300 overflow-hidden w-20 h-8 relative">
                        <button className="text-red-500 w-10 h-full font-bold text-lg hover:bg-red-50">-</button>
                        <span className="flex-grow text-center text-lg font-semibold">2</span>
                        <button className="text-green-500 w-10 h-full font-bold text-lg hover:bg-green-50">+</button>
                    </div>
                </div>
            </div>

            <div className="tebus-murah bg-green-50 mb-2 bg-gradient-to-r from-lime-100 to-lime-400">
                <div className="sale-sesction p-4">
                    <h3 className="text-lg font-semibold text-lime-900 poppins-light">Tebus Murah</h3>
                </div>
                <div className="flex space-x-2 overflow-x-auto pb-3">
                    <div className="flex items-center ml-1 bg-white p-3 rounded-lg shadow-sm">
                        <div className="w-16 h-16 bg-gray-400 mr-3 rounded"></div>
                        <div>
                            <p className="font-bold text-lime-900">Katombo</p>
                            <p className="text-sm text-gray-600">Rp 18.000</p>
                            <span className="text-xs bg-orange-50 text-orange-600 py-1 px-3 rounded">30%</span>
                        </div>
                        <button className="bg-green-200 text-green-500 py-1 px-3 rounded-lg ml-auto">+</button>
                    </div>
                    <div className="flex items-center bg-white p-3 rounded-lg shadow-sm">
                        <div className="w-16 h-16 bg-gray-400 mr-3 rounded"></div>
                        <div>
                            <p className="font-bold text-lime-900">Katombo</p>
                            <p className="text-sm text-gray-600">Rp 18.000</p>
                            <span className="text-xs bg-orange-50 text-orange-600 py-1 px-3 rounded">30%</span>
                        </div>
                        <button className="bg-green-200 text-green-500 py-1 px-3 rounded-lg ml-auto">+</button>
                    </div>
                    <div className="flex items-center bg-white p-3 rounded-lg shadow-sm">
                        <div className="w-16 h-16 bg-gray-400 mr-3 rounded"></div>
                        <div>
                            <p className="font-bold text-lime-900">Katombo</p>
                            <p className="text-sm text-gray-600">Rp 18.000</p>
                            <span className="text-xs bg-orange-50 text-orange-600 py-1 px-3 rounded">30%</span>
                        </div>
                        <button className="bg-green-200 text-green-500 py-1 px-3 rounded-lg ml-auto">+</button>
                    </div>
                </div>
            </div>

            <div className="voucher-section p-2 bg-white">
                <h3 className="voucher-title poppins-semibold text-lg mb-1">Voucher</h3>
                <div className="p-2 border-dashed border-2 border-indigo-300 rounded-lg bg-gradient-to-r from-orange-50 to-orange-200 mx-auto">
                    <h3 className="text-lg poppins-regular font-semibold text-orange-900">GERAKAN BERAMAL</h3>
                    <p>Potongan Ongkir 25%</p>
                    <p>Point Diskon Produk</p>
                </div>
            </div>
            <div className="order-type p-2 mb-6">
                <h3 className="text-lg font-semibold mb-2">Jenis Pemesanan</h3>
                <div className="space-y-2">
                    <label className="block">
                        <input type="radio" name="order-type" className="mr-2" /> Pengiriman
                    </label>
                    <label className="block">
                        <input type="radio" name="order-type" className="mr-2" /> Ambil Di Toko
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div className="fixed bottom-0 left-0 right-0 bg-grey-50 p-4 z-10 max-w-lg mx-auto poppins-regular">
        <div className="flex justify-between mb-1">
            <p className="text-gray-700">Diskon Item</p>
            <p className="text-right text-gray-700">Rp 25.000</p>
        </div>
        <div className="flex justify-between mb-1">
            <p className="text-gray-700">Kemasan</p>
            <p className="text-right text-gray-700">Rp 1.500</p>
        </div>
        <div className="flex justify-between mb-2">
            <p className="text-gray-700">Ongkos Kirim</p>
            <p className="text-right text-gray-700">Rp 8.000</p>
        </div>
        <Link to="/checkout" className="btn-checkout">
            <button className="bg-lime-400 p-3 w-full rounded-full">Lanjutkan</button>
        </Link>
    </div>
</div>
    );
}

export default Cart;

