

const Login = () => {
    return (
        <div>
            lOGIN SCREEN
        </div>
    );
}

export default Login;