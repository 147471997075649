// src/languages/id.js

const id = {
    // General
    appName: "Torufarm",
    welcome: "Selamat datang",
    home: "Beranda",
    cart: "Keranjang",
    checkout: "Checkout",
    order: "Pesanan",
    profile: "Profil",
    settings: "Pengaturan",
    logout: "Keluar",

    // Checkout Page
    checkoutTitle: "Checkout",
    deliveryAddress: "Alamat Pengiriman",
    edit: "Edit",
    items: "Item",
    deliveryTime: "Waktu Pengiriman",
    today: "Hari Ini",
    tomorrow: "Besok",
    dayAfterTomorrow: "Lusa",
    createOrder: "Buat Pesanan",

    // Cart Page
    cartTitle: "Keranjang",
    discountItem: "Diskon Item",
    packaging: "Kemasan",
    shippingCost: "Ongkos Kirim",
    continue: "Lanjutkan",

    // Profile Page
    profileTitle: "Profil",
    name: "Nama",
    phone: "Telepon",
    address: "Alamat",
    editProfile: "Edit Profil",

    // Settings Page
    settingsTitle: "Pengaturan",
    language: "Bahasa",
    notifications: "Notifikasi",
    darkMode: "Mode Gelap",

    // Notifications
    newMessage: "Pesan Baru",
    orderUpdate: "Pembaruan Pesanan",
    promo: "Promo",

    // Buttons
    save: "Simpan",
    cancel: "Batal",
    confirm: "Konfirmasi",
    back: "Kembali",

    // Errors
    error: "Terjadi kesalahan",
    networkError: "Kesalahan jaringan",
    tryAgain: "Coba lagi",

    // Placeholder Texts
    searchPlaceholder: "Cari...",
    namePlaceholder: "Nama Anda",
    phonePlaceholder: "Nomor Telepon Anda",
    addressPlaceholder: "Alamat Anda",

    // Other
    loading: "Memuat...",
    noData: "Tidak ada data",
    success: "Berhasil",
    failed: "Gagal",
};

export default id;