import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/styles.css';
import './assets/css/fonts.css';
import './index.css'; 
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './views/home/screen/Home.js';
import Cart from './views/cart/screen/cart.js';
import Checkout from './views/checkout/screen/checkout.js';
import Login from './views/user/Login.js';
import ProductDetails from './views/product/screen/ProductDetails.js';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <Home />
  // </React.StrictMode>
  <BrowserRouter>
    <Routes>
        <Route index element={<Home/>}></Route>
        <Route path='cart' element={<Cart/>}></Route>
        <Route path='checkout' element={<Checkout/>}></Route>
        <Route path='login' element={<Login/>}></Route>

        {/* Products */}
        <Route path='product/:id' element={<ProductDetails/>}></Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
