import React, { useState, useEffect } from 'react';
import { get } from '../../../data/api.js';
import { Shimmer } from '../../../component/loader.js';
import { banners } from '../../../config/init.js';

const Banner = () => {
    const [bannerData, setBannerData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const banner = await get(banners); // endpoint from init
                setBannerData(banner);
            } catch (err) {
                console.error('Failed to fetch banners:', err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchBanners();
    }, []);

    if (loading) {
        return (
            <div className="banner flex overflow-x-auto space-x-4 py-4 px-2">
                <Shimmer count={3} width="w-full sm:w-80 md:w-96 lg:w-1/2 xl:w-1/3" height="h-48" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="banner">
                <p>Error loading banners</p>
            </div>
        );
    }

    if (bannerData.length === 0) {
        return (
            <div className="banner">
                <p>No banners available</p>
            </div>
        );
    }

    return (
        <div className="banner flex overflow-x-auto space-x-4 py-4 px-2">
            {bannerData.map((ban, index) => (
                <div key={index} className="banner-item flex-shrink-0 w-full sm:w-80 md:w-96 lg:w-1/2 xl:w-1/3">
                    <img
                        src={`https://pos.torufarm.com/storage/app/public/banner/${ban.image}`}
                        alt={ban.image}
                        className="banner-img w-full h-auto rounded-lg shadow-lg object-cover"
                    />
                </div>
            ))}
        </div>
    );
};

export default Banner;