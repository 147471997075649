export class ProductModel {
    constructor()  {
        this.products = [];
        this.product_details = [];
        this.categories = [];
    }

    
    getProducts() {
        return this.products;
    }

    setProducts(products) {
        this.products = products;
    }
}