import React, { useState, useEffect } from 'react'; // Import useState and useEffect
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductProvider from "../../../provider/productProvider";
import Banner from '../widget/banner';
import { Loader, LoaderMoreProducts } from '../../../component/loader';
import FlashSaleTimer from '../widget/timer_view';
import ProductWidget from '../../../component/product_widget';
import { Link } from 'react-router-dom';

function Home() {
    const [products, setProducts] = useState([]); // State to hold products
    const [loading, setLoading] = useState(true); // State for loading indicator
    const [error, setError] = useState(null);      // State for error handling
    const [hasMore, setHasMore] = useState(true);  // State to indicate if there are more products to load
    const [page, setPage] = useState(1);           // State to manage pagination

    const productProvider = new ProductProvider();

    const fetchProductsData = async (page) => {
        try {
            const params = { page, limit: 10 };
            const productModel = await productProvider.fetchProducts(params);  // await the promise

            if (productModel && productModel.getProducts) { // Check if getProducts exists
                const fetchedProducts = productModel.getProducts();
                if (fetchedProducts.length === 0) {
                    setHasMore(false); // No more products to load
                } else {
                    setProducts([...products, ...fetchedProducts]);
                }
            } else {
                // Handle the case where productModel or getProducts is missing
                setError("Invalid product data received from the server.");
            }
        } catch (error) {
            setError(error.message); // Set the error state
        } finally {
            setLoading(false); // Set loading to false regardless of success or failure
        }
    };

    useEffect(() => {
        //const page = 1;
        fetchProductsData(page);
    }, [page]); // Include productProvider in dependency array

    if (loading && products.length === 0) {
        return <Loader />; // Display loading indicator
    }

    if (error) {
        return <div>Error: {error}</div>;  // Display error message
    }

    return (
        <div className="max-w-lg mx-auto px-4 bg-gray-50">
             <InfiniteScroll
                    dataLength={products.length}
                    next={() => setPage(page + 1)}
                    hasMore={hasMore}
                    loader={<LoaderMoreProducts/>}
                    endMessage={<p>No more products to load.</p>}>
            <header className="mt-2">
                <div className="text-green-700">
                    <p className="poppins-light">Promo Live</p>
                    <h2 className="poppins-light text-2xl">Setiap Hari</h2>
                </div>
                <Link  to='login' className="profile-icon">
                    <i className="fa-solid fa-user text-green-800 text-2xl"></i>
                </Link>
            </header>
            <main id="main">
               
                <Banner />
                <FlashSaleTimer />
                <ProductWidget products={products} type={'grid'} />
               
            </main>

            <footer>
                <Link to="/cart">
                    <div className="cart-icon relative bg-green-700 p-4 rounded-full">
                        <i className="fa-solid fa-cart-shopping text-white text-2xl"></i>
                        <span className="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold rounded-full px-2 py-1">
                            2
                        </span>
                    </div>
                </Link>
            </footer>
            </InfiniteScroll>
        </div>
    );
}

export default Home;